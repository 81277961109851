import axios from 'axios';
import React, {useState} from 'react';
import { useParams, useLocation } from 'react-router-dom';
import expiredCard from '../ExpiredImages/expired.svg';
import ApprovedIndicator from '../../shared/images/approved-indicator.svg';
import './ExpiredCard.scss'

export const ExpiredCard = () => {
    // const {SRPSessionID} = useParams();
    const location = useLocation();
    const SRPSessionID = location.state.SRPSessionID;
    console.log({location, SRPSessionID});
    const [requestedNewLink, setRequestedNewLink] = useState(false);

    const requestNewLink = async () => {
        const decisionsEndpoint = global.location.href.includes("references.appliedmedical.com")  ? 'https://bpm.appliedmedical.com/Primary/?FlowAlias=SRPRequestSession' : 'https://bpmqa.appliedmedical.com/Primary/?FlowAlias=SRPRequestSession';
        console.log({decisionsEndpoint})
        console.log({SRPSessionID});
        let response = await axios(decisionsEndpoint,{
            params: {
                sessionid: "NS-2534cfef-2859-11ec-9166-005056bf3f1b",
                outputtype: "Json",
                SRPSessionID: SRPSessionID,
            }
        }).then( (response) => {
            // console.log(response);
            setRequestedNewLink(true);
        } ).catch( (error) => {
            console.log({error});
        } )
    }
    return(
        <div styleName="expired-card-container">
            <div>
                <img src={expiredCard} alt="expired" styleName="expired-image"></img>
                <h1 styleName="expired-header">Link Expired</h1>
                <p styleName="expired-paragraph">
                    Your unique email link has expired. You can request another link by clicking the button below.
                </p>
                {
                    requestedNewLink  ? (
                    <div styleName = 'new-link-requested'>
                        <div styleName = 'approved-img-container'><img  src = {ApprovedIndicator}/></div>
                        <div styleName = 'double-column'>
                            <div data-cy='new-link-header' styleName = 'new-link-header'>NEW LINK REQUESTED</div>
                            <div styleName = 'new-link-message'>You will be notified by email when the new link has been created.</div>
                        </div>
                    </div> 
                    ) : (
                        <>
                            <button styleName="expired-button" data-cy = "expired-btn" onClick = {requestNewLink}>
                                Request New Link
                            </button>
                            <div styleName = "contact-us">
                                <span>
                                    If this issue persists, contact us <a styleName = 'contact-link' href = "#">here</a>
                                </span>
                            </div>
                        </>
                    )
                }
                
            </div>
        </div>
    )
}