import React from 'react';
import NoPageFoundImage from '../shared/images/no-page-found.svg';
import './NoPageFound.scss';

export const NoPageFound = ({}) => {
    return(
        <div styleName = 'container'>
            <div>
                <img styleName="expired-image" src= {NoPageFoundImage} alt = 'no page found'/>
                <h1 styleName = 'header'>PAGE NOT FOUND</h1>
                <p styleName = 'paragraph'>
                    {"Looks like this page doesn`t exist or was removed. If you type the URL directly, please make sure the spelling is correct."}
                </p>
            </div>
        </div>
    )
}