import React, {useEffect, useState} from "react";
import { ReferenceListHeader } from './ReferenceListHeader/ReferenceListHeader.jsx'
import {ReferenceListSearch} from './ReferenceListSearch/ReferenceListSearch.jsx'
import { ReferenceListData } from './ReferenceListData/ReferenceListData.jsx'
import './ReferenceList.scss';

export const ReferenceList = () => {
    const [searchTerm, setSearchTerm] = useState(""); //search term for search bar
    const [filters, setFilters] = useState([]); // filters that are being selected
    const [applyFilter, setApplyFilter] = useState(false); //used to determine when to filter data
    const [useFilteredData, setUseFilteredData] = useState(false); //used to tell whether to display filtered data or not
    return(
        <div styleName="ref-container">
            <div styleName="ref-wrapper">
                <ReferenceListHeader/>
                <ReferenceListSearch 
                    searchTerm = {searchTerm} 
                    setSearchTerm = {setSearchTerm}
                    filters = {filters}
                    setFilters = {setFilters}
                    applyFilter = {applyFilter}
                    setApplyFilter = {setApplyFilter}
                    useFilteredData = {useFilteredData}
                    setUseFilteredData = {setUseFilteredData}
                />
                <ReferenceListData
                    searchTerm = {searchTerm}
                    filters = {filters}
                    applyFilter = {applyFilter}
                    setApplyFilter = {setApplyFilter}
                    useFilteredData = {useFilteredData}
                />
            </div>
        </div>
    )
}